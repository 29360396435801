.footer-section {
    background-color: var(--black);
    padding: 4rem 0;
    width: 100%;
    color: white;
}

.footer-wrapper {
    width: fit-content;
    margin: 0 auto;   
}

.back-to-top {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}

.back-to-top button {
    border: 1px solid white;
    background: none;
    color: white;
    font-size: 16px;
    padding: 10px 25px;
    border-radius: 12px;
    transition: all 0.1s ease-out;
    margin-bottom: 10px;
}

.back-to-top button:hover {
    border: 1px solid var(--red);
    transition: all 0.1s ease-in;
    background: var(--red);
    color: white;  
    cursor: pointer;
}

.footer-links-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}

.footer-ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-ul li {
    margin: 0.4rem 2rem;
    cursor: pointer;
}

.disclaimer_ {
    text-align: center;
    max-width: 700px;
    padding: 0 2rem;
}

.media-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.footer-icon {
    color: white;
    margin: 0 0.8rem;
    font-size: 22px;
}

@media screen and (max-width: 500px){
    .footer-wrapper {
        width: 100%;
        margin: 0 auto;  
    }
    
    .footer-links-wrapper {
        display: flex;
        max-width: 600px;
        justify-content: center;
        padding-bottom: 2rem;
    }

    .footer-ul {
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .footer-ul li {
        margin: 0.4rem 1rem;
        cursor: pointer;
    }
}