.title-ui {
    padding: 1.8rem 0;
    background: var(--black);
}
/* MAIN TITLE GRID */
.title-grid {
  display: grid;
  grid-template-columns: 20% fit-content(100%) 1fr ;
  gap: 15px;
  margin: 0 4rem;
}

.title-grid h1 {
    color: var(--red);
    font-weight: 200;
    letter-spacing: 4px;
}

.cross-line {
    background: #dfdfdf;
    width: 100%;
    height: 0.5px;
    margin: auto 0;
}

/* DESCRIPTION GRID */
.desc-grid {
    display: grid;
    grid-template-columns: 20% fit-content(100%) 1fr;
    gap: 15px;
    margin: 0 4rem;
}

.desc-line {
    width: 100%;
    margin: auto 0;
}

.title-desc{
    color: #dfdfdf;
}

@media only screen and (max-width: 600px) {
    .cross-line {
        display: none;
    }
    .desc-grid {
        display: block;
    }
}