
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;700&display=swap');
 

* {
    font-family: 'Karla', sans-serif;
    margin: 0;
    --red: #F30039;
    --black: #040508;
    --white: #ffffff;
}

.App {
    display: block;
}

body{
    overflow-x: hidden;
    /* overflow: -moz-scrollbars-none; */
    /* -ms-overflow-style: none;  */
}

::-webkit-scrollbar { 
    width: 10px
} 

::-webkit-scrollbar-track {
    
    background: #1d1d1d;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--red);
    /* border-radius: 10px; */
  }

h3 {
    font-size: 20px;
}