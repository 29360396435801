.slider-hist {
    height: 500px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    overflow: hidden;
    margin: 0 auto;
}

.img-active {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    transition: height 0.4s;
}

.img-inactive {
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.selector {
    margin: 0 25px;
    z-index: 1;
}

.selector ul {
    list-style: none;
    width: 180px;
}

.li-active {
    transition: all 0.5s;
    color: red;
    font-weight: bold;
    margin: 1rem 0 1rem 0.5rem;
    cursor: pointer;
}

.li-inactive {
    margin: 1rem 0;
    cursor: pointer;
}

.description-hist {
    margin: 0 25px;
    max-width: 520px;
    z-index: 1;
    line-height: 24px;
}

.desc-inactive {
    display: none;
}

.desc-active {
    display: block;
}

.logo {
    display: block;
}

.time-period-ul {
    margin: 0;
    padding: 1rem 0;
}

.silder-btn {
    display: flex;
    transform: rotate(90deg);
    font-size: 28px;
    font-weight: bold;
    background: none;
    color: red;
    border: none;
    width: 50px;
    padding: 2px 20px;
    margin: 0 auto;
    cursor: pointer;
}

.hist-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0,0.5);
}

@media only screen and (max-width: 500px) {
    .description-hist {
        max-width: 520px;
        line-height: 16px;
        font-size: 14px;
        margin: 0 10px 0 0;
    }
    
    .selector {
        margin-left: 8px;
        z-index: 1;
    }
    
    .selector ul {
        width: 140px;
        font-size: 14px;
    }
    
    .li-active {
        margin: 1rem 0rem;
    }

    .slider-hist {
        height: 440px;
    }
}
