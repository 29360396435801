.events-section {
    display: block;
    width: 100%;
    margin: 0 auto;
    background-color: var(--black);
    padding-bottom: 3rem;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.event-selector-box {
    max-width: 400px;
    margin: 2rem auto;
}