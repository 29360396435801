/* The width of this was NOT coverting the whole page so this was not being centered */
.locations-section {
    background-color: var(--black);
    width: 100%;
}

.map {
    position: relative;
    display: block;
}

.map-img-wrapper {
    display: flex;
    position: relative;
    min-width: 360px;
    margin:auto;
    z-index: 1;
    width: 50%;
}

.map-img {
    width: 100%;
}

.item {
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    background-color: blue;
    top: 60%;
    right: 10%;
}
