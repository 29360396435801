.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 280px;
    height: 320px;
    margin: 0.6rem 1rem;
    padding: 0.5rem 1rem;
    background-color: rgb(230, 233, 236);
    border-radius: 10px;
}

.card h3 {
  margin-top: 4px;
  font-size: 20px;
}

.cardImg {
    border-radius: 10px;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 140px;
}

.cardImg img {
    aspect-ratio: auto;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.card-location {
  font-size: 14px;
  color: var(--red);
  margin: 0;
}

.card-text-container {
  width: 100%;
  height: 56px;
  overflow: hidden;
  margin: 10px 0 12px 0;
}

.card p {
  font-size: 16px;
}

.card-btns-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.card button {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  background: none;
  font-size: 16px;
  width: 130px;
  padding: 8px 0;
}

.card-more-btn {
  display: flex;
  border: 1px solid var(--black);
  transition: all 0.2s ease-out;
}

.card-more-btn:hover {
  background: rgb(0, 0, 0,0.1);
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.more-ico {
  height: 15px;
  margin-top: 2px;
}

.card-dir-btn {
  display: flex;
  border: 1px solid var(--red);
  color: var(--red);
  transition: all 0.1s ease-out;
}

.card-dir-btn:hover {
  background: var(--red);
  color: white;
  transition: all 0.1s ease-in;
  cursor: pointer;
}

.loc-icon-not-hover {
  color: var(--red);
  transition: all 0.1s ease-out;
}

.loc-icon-hover {
  transition: all 0.1s ease-in;
  color: white;
}

@media only screen and (max-width: 500px) {
  .card {
    max-width: 70%;
  }
  .card button {
    width: 120px;
  }
}




