.hover-not-display {
    display: flex;
    height: 0;
    width: 0;
    position: absolute;
    overflow: hidden;   
}

.hover-display {
    display: flex;
    width: 360px;
    height: 140px;
    background-color: white;
    border-radius: 25px;
    position: absolute;
    transform: translateY(-98px);
    overflow: hidden;
    z-index: 1;
    transition: height .2s ease;
}

.hover-right{
    width: 40%;
}

.hover-left {
    display: block;
    width: 60%;
    margin: auto 12px;
}

.hover-description {
    height: 60px;
    margin: 5px 0;
    overflow: hidden;
}


.hover-btn-container {
    display: flex;
    justify-content: space-between;
}

.hover-btn-container button {
    height: 34px;
    width: 100px;
    border-radius: 10px;
    cursor: pointer;
}

.hover-btn-dir {
    background: var(--red);
    border: none;
    color: white;
    transition: all 0.2s ease-out;
}

.hover-btn-dir:hover {
    background: rgb(197, 15, 15);
    transition: all 0.2s ease-in;
}

.hover-btn-more {
    background: var(--white);
    border: 1px solid var(--black);
    color: var(--black);
    transition: all 0.2s ease-out;
}

.hover-btn-more:hover {
    cursor: pointer;
    background: rgb(0, 0, 0,0.1);
    transition: all 0.2s ease-in;
}

.hover-img-cont {
    height: 100%;
}

.hover-img-cont img {
    width: 100%;
    height: 100%;
    object-fit: cover;   
}

@media only screen and (max-width: 768px) {
    .hover-description {
       display: none;
    }

    .hover-right {
        display: none;
    }

    .hover-left{
        width: 100%;
        margin: 10px 5px;
    }

    .hover-display {
        width: fit-content;
        height: 80px;
        transform: translateY(-62px);
    } 

    .hover-btn-container button {
        margin-top: 5px;
        display: inline-block;
        width: 90px;
        font-size: 14px;
    }

}