.hero-section {
    width: 100%;
    height: 100vh;
    position: relative; 
}

.hero-vid-overlay{
    position: absolute;
    background-color: rgb(0, 0, 0,0.35);
    inset: 0;
}

.hero-vid {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.img-backup-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
}

.img-backup-wrapper img {
    width: 100%;
}

.overlay-hero {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    z-index: 1;
}

#hero-title {
    color: white;
    font-size: 42px;
    text-align: center;
    letter-spacing: 0.3rem;
    font-weight: 300;  
}

#hero-title span {
    display: inline-block;
    color: var(--red);
    background-color: var(--red);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.navbar {
    position: absolute;
    inset: -85% 0 0 0;
    margin: auto auto;
    width: fit-content;
    height: 50px;
    overflow: hidden;
    border-radius: 15px;
    font-weight: 200;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.navbar ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.navbar ul li {
    padding: 0 1.2rem;
    font-size: 16px;    
    height: 50px;
    line-height: 50px;
    transition:  all 0.1s ease-out;
    color: white;
}

.navbar ul li:hover {
    transition: all 0.1s ease-in;
    background-color: var(--red);  
    cursor: pointer;
    color: white;
}

.disclaimer {
    padding-top: 2.6rem;
    background-color: var(--black);
    color: #6e6e6e;
    text-align: center;
}

.disclaimer a {
    text-decoration: underline;
    color: #6e6e6e;
}

@media only screen and (max-width:400px) {

    .navbar {
        width: 95%;
    }

    .navbar ul {
        width: 95%;
    }
    .navbar ul li {
        padding: 0 4.5%;
    }

    #hero-title {
        font-size: 32px;
    }
}