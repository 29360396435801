.featured-section {
    display: flex;
    width: 100%;
    height: 420px;
    position: relative;
    color: white;
    justify-content: center;
    align-items: center;
}

.featured-info {
    display: block;
    max-width: 460px;
    text-align: center;
}

.line-fd {
    width: 100%;
    height: 1px;
    background-color: white;
    margin: 5px auto;
}

.featured-info p {
    margin: 20px 0px;
}

.featured-info button {
    border: 1px solid white;
    background: none;
    color: white;
    font-size: 16px;
    padding: 10px 25px;
    border-radius: 12px;
    transition: all 0.1s ease-out;
}

.featured-info button:hover {
    border: 1px solid var(--red);
    transition: all 0.1s ease-in;
    background: var(--red);
    color: white;  
    cursor: pointer;
}

.featured-section img  {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    inset: 0;
    z-index: -2;
}

.overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1;
}    


@media only screen and (max-width: 500px) {
    .featured-info {
        display: block;
        max-width: 320px;
        text-align: center;
    }
}