.map-icon {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 80px;
    height: fit-content;
    cursor: default;
    justify-content: center;
    align-items: center;
}

.map-icon img  {
    width: 40px;
}

.location-title {
    position: relative;
    text-align: center;
    width: fit-content;
    font-size: 12px;
    color: white;
    font-weight: bold;
}

@media only screen and (max-width: 820px) {
    .map-icon img{
        width: 32px;
    }
    
    .location-title {
        font-size: 12px;
    }
}